*{
    margin: 0;
    padding: 0;
    outline: 0;
}


/* Body */
body{
    /* background-image: linear-gradient(to bottom right, #cdaa7d 35%, #ffffff); */
    background-color: #e6e6e6;
    height: 100vh;
}


/* NavBar */
.myNav{
    position: fixed !important;
    display: flex !important;
    justify-content: space-between !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-image: linear-gradient(to bottom,#ffffff, #344e41);
}
.linkContainer{
    display: flex !important;
    justify-content: space-evenly !important;
}
.myNav img{
    border-radius: .5rem;
    width: 200px;
    margin-left: 1%;
}
@media (max-width:1000px) {
    .myNav{
        height: auto;
        padding: 1%;
        margin-bottom: 1%;
    }
    /* .myNav img{
        width: 125px;
    } */
}
.link{
    color: #ffffff !important;
    margin-right: 25%;
    font-weight: bold !important;
    font-size:large !important;
    transition: cubic-bezier(.05,.45,.74,1.1) .25s !important;
    white-space: nowrap;
}
.link:hover {
    color: #000000 !important;
    transform: scale(1.3);
}

.appointmentBtn{
    border: 2px solid #000000 !important;
    background-color: #24a060 !important;
    color: white !important;
    font-weight: bold !important;
}
.appointmentBtn:hover{
    background-color: rgb(0, 0, 0) !important;
    color: white !important;
}

.socials{
    color:#ffffff !important;
}
.socials:hover{
    color: rgb(0, 0, 0) !important;
}

@media (max-width:1400px) {
    .link{
        margin-left: 0 !important;
    }
}

/* Mobile Nav */
.side-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #081c15;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}
.side-nav-container h2{
    color: white;
    margin-top: 35%;
    text-decoration: underline;
}

.side-nav-container.expanded {
    transform: translateX(0);
}
.side-nav-links {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-nav-link {
    margin: 10px 0;
    text-decoration: none;
    color: #ffffff;
    font-size: x-large;
    text-decoration: underline;
}
.side-nav-theme-toggle {
    margin-top: 20px;
}

.side-nav-theme-toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 5%;
}

.side-nav-theme-toggle-button .icon {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}

.side-nav-theme-toggle-button .sun {
    opacity: 0;
    transform: scale(0.5) rotate(90deg);
}

.side-nav-theme-toggle-button .moon {
    opacity: 0;
    transform: scale(0.5) rotate(-90deg);
}

.side-nav-theme-toggle-button .sun.show {
    opacity: 1;
    transform: scale(1) rotate(0deg);
}

.side-nav-theme-toggle-button .moon.show {
    opacity: 1;
    transform: scale(1) rotate(0deg);
}
#mobileButton{
    margin-top: 25%;
    background-color: #24a060;
    padding: 2.5%;
    border-radius: .5rem;
    border: 3px solid white;
    font-weight: bold;
    text-decoration: none;
    color: white !important;
}
#mobileSocial{
    margin-top: 10%;
    display: flex;
}
#note{
    color: white;
}

/* Hamburger Icon */
.hamburger-container {
    position: fixed;
    top: 15px;
    left: 20px;
    z-index: 1100;
    border-radius: .5rem;
    padding: 1%;
}

.hamburger-icon {
    cursor: pointer;
    font-size: 30px;
    color:  #000000;
    transition: transform 1 ease-in-out, opacity 1 ease-in-out;
}

.hamburger-icon,
.close-icon {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    transform: scale(0.5);
}

.hamburger-icon {
    background-color: white;
    opacity: 1;
    transform: scale(1);
    border: 3px solid #00cc00;
    border-radius: .5rem;
}
.icon-container.expanded .hamburger-icon {
    opacity: 0;
    transform: scale(0.5);
}

.close-icon {
    opacity: 0;
    transform: scale(0.5);
    border: 3px solid rgb(255, 255, 255);
    border-radius: .5rem;
    padding: 2%;
}

.icon-container.expanded .close-icon {
    opacity: 1;
    transform: scale(1);
}

/* CTA */
.CTA{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}
.highlight{
    color: #24a060;
    font-weight: bold;
    font-size:150%;
    text-decoration: underline;
}
.about{
    padding: 2%;
    background-image: url('./components/images/IMG_0193.jpg');
    background-size: cover;
    background-position: center;
    width: 35%;
    height: 518px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
}
.overlay {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: .5rem;
    padding: 5%;
    color: rgb(0, 0, 0);
}
.about p{
    max-width: 600px;
    font-weight: bold;
}
.callbutton{
    padding: 1%;
    font-weight: bold;
    background-color: #24a060;
    border-radius: .5rem;
    color: rgb(255, 255, 255);
    border: 2px solid black;
    transition: background-color .450px cubic-bezier(0.450px 0.82, 0.165, 1);
}
.callbutton:hover{
    background-color: #000000;
    color: white;
}

.CTAForm{
    background-color: #f0f0f0;
    padding: 1%;
    height: 518px;
    border-radius: .5rem;
}
.CTAInput{
    margin: 2%;
    border: 2px solid rgb(178, 178, 178);
    font-style: italic;
    padding: 2%;
}
.CTAInput:hover{
    background-color: rgba(0, 0, 0, 0.1);
}
.CTASubmit{
    background-color: #24a060;
    border: 2px solid black;
    padding: 2%;
    width: 50%;
    font-weight: bold;
    transition: background-color .450px cubic-bezier(0.450px 0.82, 0.165, 1);
    border-radius: .5rem;
    color: white;
}
.CTASubmit:hover{
    background-color: #000000;
    color: white;
}
@media (max-width:1100px) {
    .about{
        width: 100%;
    }
    .callbutton{
        padding: 2%;
    }
    .CTA{
        display: block;
        margin-top: 0;
    }
    .CTAForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .about p{
        max-width: fit-content;
        font-weight: bold;
    }
}



/* Services */
.containerServ{
    background-color: #ffffff;
    padding: 2.5%;
    border-radius: 2rem;
    margin: 2%;
    color: rgb(0, 0, 0);
    border: 2px solid black;
}
.estimate{
    background-color: #24a060;
    margin-top: 20%;
    border: 2px solid black;
    padding: 8px;
    width: 50%;
    font-weight: bold;
    transition: .25s;
    border-radius: .5rem;
    color: white;
    max-height: 15px;
}
.estimate:hover{
    background-color: black !important;
}
.servicesContainer{
    display: flex;
    justify-content: space-evenly;
}
.services{
    margin: 1%;
    border-radius: 2rem;
    width: 33%;
    margin-bottom: 5%;
}
.services img{
    border-radius: 1.8rem;
    width: 100%;
    height: 500px;
}
@media (max-width:1400px) {
    .servicesContainer{
        display: inline;
    }
    .services{
        border: none;
        width: 100%;
    }
    .services img{
        border-radius: 1.8rem;
        width: 100%;
        height: auto;
    }
}
/* Testimonials */
.testimonialContainer{
    /* background-image: linear-gradient(to bottom, #cdaa7d 10%, #ffffff); */
    padding: 2%;
    margin: 2%;
}
.testimonialContainer h2 {
    text-decoration: underline;
}
.testimonial{
    border: 2px solid black !important;
    border-radius: 1rem;
    padding: 2%;
    margin-left: 1%;
    margin-right: 1%;
}

/* Footer */
.foot{
    padding-bottom: 1%;
    background-image: linear-gradient(to top, #344e41 , #ffffff);
}
.foot img{
    border-radius: 1rem;
    margin-bottom: 1%;
    width: 15%;
}
@media (max-width:1200px) {
    .foot img{
        width: 35%;
    }
}
.foot p{
    font-weight: bold;
}
.footSocials{
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-bottom: 1%;
}
.footSocials a{
    color: white;
}
.footSocials a:hover{
    color: rgb(0, 0, 0);
}
.rfCoder{
    color: rgb(0, 255, 4);
}

/* About Page */
.aboutUs {
    margin-top: 10%; /* Adjusted for better spacing */
}

.dream {
    border: 4px solid #24a060;
    margin-bottom: 2%;
    padding: 5%;
    border-radius: 1rem;
}

.dream p {
    font-weight: bold;
}

.aboutContainer {
    display: flex;
    flex-wrap: wrap; /* Ensures flex items wrap properly */
    align-items: center;
    gap: 5%;
    margin: 2%;
}

.aboutContainer h2 {
    text-decoration: underline;
}

.aboutLeft, .aboutRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    background-color: rgba(36, 160, 96, 0.5);
    color: white;
    border-top: 3px solid #24a060;
    border-bottom: 3px solid #24a060;
    border-radius: 0.5rem;
    min-height: 450px;
    max-height: 450px;
    flex: 1; /* Ensures they take equal space */
    margin-top: 2%;
    text-align: left;
}

.aboutRight {
    text-align: right;
}

.aboutPicWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutPic {
    border-radius: 1rem;
    width: 100%;
    max-width: 600px;
    height: auto;
}

@media (max-width: 1200px) {
    .aboutContainer {
        flex-direction: column;
        text-align: center;
    }

    .aboutPic {
        width: 100%;
    }

    .aboutLeft, .aboutRight {
        min-height: auto;
        max-height: auto;
        text-align: center;
        padding: 5%;
    }

    .aboutContainer:nth-of-type(2) {
        display: none;
    }
}


/* About 2 */
/* .about2Container{
    display: flex;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 50vw;
    height: 100vh;
}
.gridImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.values{
    display: flex;
    gap: 2%;
} */

/* Contact */
.contactCont{
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}
.contactCont form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    border: 3px solid black;
    border-radius: .75rem;
    padding: 2%;
}
.contactCont form > input{
    padding: 1%;
    margin-bottom: 5%;
}
.info{
    display: flex;
    gap: 10px;
}
.info input{
    width: 50%;
    margin-bottom: 5%;
    padding: 1%;
}
#submit{
    background-color: #24a060;
    border-radius: .5rem;
    padding: 1%;
    color: white;
    font-weight: bold;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    transition: .25s;
}
#submit:hover{
    background-color: #000000;
}
@media (max-width:1024px) {
    .contactCont{
        padding-top: 25%;
        margin-bottom: 35%;
    }
    .contactCont form{
        width: 80%;
    }
}

/* Gallery.css */
.galleryCont{
    padding: 10%;
}
.galleryCont h1{
    margin-bottom: 2%;
    text-decoration: underline;
}
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
}

.gallery-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.modal-body img {
    width: 100%;
    border-radius: 8px;
  }

/* Responsive adjustments */
@media (max-width: 768px) {
    .galleryCont{
        padding-top: 15%;
    }
    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.gallery-image {
    height: 150px;
}
}

@media (max-width: 480px) {
.gallery {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.gallery-image {
    height: 100px;
}
}

/* Thank you */
.thankYouCont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.thank-you-message {
    text-align: center;
    background-color: #ffffff;
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
    margin-bottom: 10%;
}

.thank-you-message h1 {
    color: #24a060;
    margin-bottom: 20px;
}

.thank-you-message p {
    color: #333;
    margin-bottom: 20px;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 5px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

.btn.primary {
    background-color: #24a060;
    color: white;
    border: 2px solid #24a060;
}

.btn.secondary {
    background-color: white;
    color: #24a060;
    border: 2px solid #24a060;
}

.btn.primary:hover, .btn.secondary:hover {
    background-color: #000000;
    color: white;
}

@media (max-width: 1024px) {
.thank-you-message {
    margin-top: 20%;
    max-width: 90%;
    margin-bottom: 15%;
}

.btn {
    width: 100%;
    box-sizing: border-box;
}
}

/* Service Page */
.servPageCont {
    padding-top: 10%;
    color: rgb(0, 0, 0);
}

.servicesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2%;
}

.cont{
    display: flex;
    justify-content: space-evenly;
}

.firstServ {
    position: relative;
    padding: 1rem;
    border-radius: .5rem;
}

.firstServ p{
    font-size: larger;
    color: white;
    font-weight: 400;
}
.image-overlay {
    position: relative;
}

.firstServImage {
    width: 100%;
    height: 500px;
    border-radius: .5rem;
}

.serviceOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
}

.serviceOverlay h1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
}

.text-center h3 {
    color: #24a060;
}

.additionalServicesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 2%;
}

.serviceItem {
    background-color: #081c15;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
}

@media (max-width:1400px) {
    .firstServImage{
        height: 300px;
    }
}

@media (max-width: 768px) {
    .cont{
        flex-direction: column;
    }
    .firstServImage{
        height: auto;
    }
    .servicesGrid {
        grid-template-columns: 1fr;
    }
}

